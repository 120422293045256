import React from 'react';
import Layout from '../components/layout/Layout';
import LeadNews from '../components/news/LeadNews';
import NewsList from '../components/news/NewsList';
import image1 from '../assets/images/news/20220512-thumbnail.jpg';
import PageTitle from '../components/common/PageTitle';

function News() {
  const news = {
    img: image1,
    date: 'MAY 12, 2022',
    title: 'LawAdvisor Secures Backing from Legal Heavyweights with Seed Round ',
    description: 'Legaltech specialist’s investors include leading legal and technology innovators from Silicon Valley, including Microsoft and Gilead Sciences. Creates advisory panel to help scale and seize opportunities.',
    url: '/news/lawadvisor-secures-backing-from-legal-heavyweights-with-seed-round',
  };

  return (
    <Layout
      title="News about us"
      description="LawAdvisor was founded in 2015 in Melbourne, Australia with a fearless and ambitious belief: that everybody should have access to justice."
      image="/metaimages/meta-image-la-ventures.png"
    >
      <PageTitle title="News about us" />
      <LeadNews news={news} />
      <NewsList className="news-page" />
    </Layout>
  );
}

export default News;
