import React from 'react';

function PageTitle({ title }) {
  return (
    <div className={`mt-0 pt-0 mt-sm-5 pt-sm-5 mb-80 mb-sm-40 fit-width`}>
      <h2 className="mb-0">
        {title}
      </h2>
    </div>
  );
}

export default PageTitle;