import { Link } from 'gatsby';
import React from 'react';

function LeadNews({ news, className }) {
  return (
    <div className={`fit-width ${className || ""}`}>
      <div className="row pl-0 pr-0 mt-3" style={{
        display: "flex",
        alignItems: "center"
      }}>
        <div className="col-xl-7 col-md-7 col-sm-12">
          <Link to={news.url}>
            <img className="rounded" src={news.img} />
          </Link>
        </div>
        <div className="col-xl-5 col-md-5 col-sm-12">
          <span className="date_time mb-12">{news.date}</span>
          <Link to={news.url} className="lead-news">
            <h3 className="title mb-12">{news.title}</h3>
            <p className="description">{news.description}</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LeadNews;