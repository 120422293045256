import React from 'react';

import image0 from "../../assets/images/news/_03.jpg";
import image1 from "../../assets/images/news/01.png";
import image2 from "../../assets/images/news/02.png";
import image3 from "../../assets/images/news/03.png";
import image4 from "../../assets/images/news/04.png";
import image5 from "../../assets/images/news/05.png";
import image6 from "../../assets/images/news/competition-hots-up.png";
import image7 from "../../assets/images/news/la-barclays-partnership.png";
import image8 from "../../assets/images/news/la-ventures-barclays.jpg";
import image9 from "../../assets/images/news/_05.jpg";
import image10 from "../../assets/images/news/thumb-1.jpg";
import image11 from "../../assets/images/news/thumb-2.jpg";
import image12 from "../../assets/images/news/thumb-3.jpg";
import image13 from "../../assets/images/news/thumb-4.jpg";
import image14 from "../../assets/images/news/thumb-5.jpg";
import image15 from "../../assets/images/news/thumb-6.jpg";
import image16 from "../../assets/images/news/thumb-7.jpg";
import image17 from "../../assets/images/news/thumb-8.jpg";
import image18 from "../../assets/images/news/thumb-9.jpg";

import News from './News';

function NewsList({ className }) {

  const news = [
    {
      img: image14,
      date: 'MAY 12, 2022',
      title: 'LawAdvisor Raises $5M Investment, With Former Orrick CEO, Silicon Valley Funders\' Support',
      description: 'LawAdvisor, which released two products in 2021 designed with input from Barclays and a host of law firms, will bring former Orrick CEO and chairman Ralph Baxter and other investors on as advisers.',
      url: 'https://www.law.com/legaltechnews/2022/05/11/lawadvisor-raises-5-million-investment-with-former-orrick-ceo-silicon-valley-funders-support/'
    },
    {
      img: image13,
      date: 'MAY 12, 2022',
      title: 'Melbourne-founded LawAdvisor secures $7m in backing after raising the bar with new technology',
      description: 'Melbourne-founded Legal-tech startup LawAdvisor Ventures has raised $7.18 million from backers, including Google Maps founder Lars Rasmussen...',
      url: 'https://www.businessnewsaustralia.com/articles/lawadvisor-secures--7m-in-backing-after-raising-the-bar-with-new-technology.html'
    },
    {
      img: image16,
      date: 'MAY 12, 2022',
      title: '“Google for law” startup LawAdvisor scores $5m investment',
      description: 'LawAdvisor is the latest startup aiming to revolutionise legal practices, and with $5m new investment underneath its belt, it has just strengthened its ability to smash efficiency roadblocks for lawyers.',
      url: 'https://www.verdict.co.uk/google-for-law-startup-lawadvisor-scores-5m-investment/'
    },
    {
      img: image15,
      date: 'MAY 12, 2022',
      title: 'LawAdvisor raises $5m seed funding from heavyweight investors',
      description: 'Melbourne-founded legaltech startup LawAdvisor Ventures has raised USD$5m of investment in a seed round involving heavyweight investors from the likes of Microsoft and Gilead Sciences, as well as Google Maps founder Lars Rasmussen.',
      url: 'https://legaltechnology.com/2022/05/12/lawadvisor-raises-5m-seed-funding-from-heavyweight-investors/'
    },
    {
      img: image18,
      date: 'MAY 12, 2022',
      title: 'Ex-Orrick CEO Joins Seed Round Funding For Legal Tech Co.',
      description: 'A group of legal industry giants, including the former chairman and CEO of Orrick Herrington & Sutcliffe LLP, has backed legal technology software company LawAdvisor Ventures in the form of a...',
      url: 'https://www.law360.com/pulse/amp/articles/1492636'
    },
    {
      img: image12,
      date: 'MAY 12, 2022',
      title: 'Lawtech start-ups bag multi-million pound investments',
      description: 'LawAdvisor Ventures, a lawtech start-up with software platforms aimed at corporate in-house legal teams has raised $5m (£4m) from big-name Silicon Valley backers associated with Google, Facebook and Microsoft.',
      url: 'https://www.legalfutures.co.uk/latest-news/lawtech-start-ups-bag-multi-million-pound-investments'
    },
    {
      img: image10,
      date: 'MAY 12, 2022',
      title: 'Big investors inject US$5m into Melbourne-born legaltech startup',
      description: 'A group of major investors have invested US$5m into Melbourne-founded legaltech company LawAdvisor Ventures in a seed round.',
      url: 'https://www.thelawyermag.com/au/news/general/big-investors-inject-us5m-into-melbourne-born-legaltech-startup/405702'
    },
    {
      img: image11,
      date: 'MAY 12, 2022',
      title: 'Is this the start of something big in Lawtech',
      description: 'London headquartered Lawtech firm LawAdvisor Ventures has raised US$5 million in a seed round led by some significant industry angel investors. The funding will help the company scale and further accelerate development in its product portfolio, helping to change how law is practised.',
      url: 'https://www.enterprisetimes.co.uk/2022/05/12/is-this-the-start-of-something-big-in-lawtech/'
    },
    {
      img: image17,
      date: 'MAY 12, 2022',
      title: 'Google Map founder backs LawAdvisor in $5M round to transform legal service delivery',
      description: 'LawAdvisor Venture, a London-based legal technology platform, announced on Thursday that it has secured $5M funding in the Seed round from Ralph Baxter, Ben Davey, Jason Barnwell, Gary Tully, and Lars Rasmussen, founder of Google Maps.',
      url: 'https://techfundingnews.com/google-map-founder-backs-lawadvisor-in-5m-round-to-transform-legal-service-delivery/'
    },
    {
      img: image0,
      date: 'JANUARY 27, 2021',
      title: 'LawAdvisor launches new solutions in collaboration with Barclays and leading law firms to revolutionise the practice of law',
      description: 'Today legal technology specialist LawAdvisor, has launched two innovative new legal technology solutions, aimed at transforming the way law firms work with corporate in-house legal departments and legal matters are managed.',
      url: '/news/lawadvisor-launches-new-solutions-in-collaboration-with-barclays',
    },
    {
      img: image9,
      date: 'JANUARY 27, 2021',
      title: 'Amid Digital Legal Changes, LawAdvisor Launchs Tech Tools',
      description: 'As the growing intersection between law and technology continues to take hold in the legal industry, legal technology company LawAdvisor has launched two new products …',
      url: 'https://www.law360.com/banking/articles/1348564/amid-digital-legal-changes-lawadvisor-launchs-tech-tools-',
    },
    {
      img: image8,
      date: 'JANUARY 27, 2021',
      title: 'Barclays Adds Panel Law Firms to New Collaborative Platform',
      description: 'The new tech solution is one of two the bank created with LawAdvisor and firms including Allen & Overy and Paul Hastings.',
      url: 'https://www.law.com/international-edition/2021/01/27/barclays-adds-panel-law-firms-to-new-collaborative-platform/',
    },
    {
      img: image6,
      date: 'JANUARY 26, 2021',
      title: 'LawAdvisor partners with Barclays, law firms, on new legal ops tools',
      description: 'Legal tech company LawAdvisor is unveiling two new products for corporate legal departments and law firms with the help of Barclays plc and several law firms, including Bird & Bird.',
      url: 'https://www.reuters.com/article/legaltech-lawadvisor/lawadvisor-partners-with-barclays-law-firms-on-new-legal-ops-tools-idUSL1N2K10QN',
    },
    {
      img: image7,
      date: "JANUARY 26, 2021",
      title: "Competition hots up to become standard software for in-house teams",
      description: "A software platform aimed at bringing together the various apps used by corporate legal teams is launched today.",
      url: "https://www.legalfutures.co.uk/latest-news/competition-hots-up-to-become-standard-software-for-in-house-teams"
    },
    {
      img: image1,
      date: 'NOVEMBER 26, 2020',
      title: 'Aussie legaltech specialist LawAdvisor looks to grow its global market share with London move',
      description: 'The startup, which is backed by a co-founder of Google Maps, has moved its headquarters to the British capital to position itself at the centre of the global legaltech industry...',
      url: 'https://www.roboticslawjournal.com/news/aussie-legaltech-specialist-lawadvisor-looks-to-grow-its-global-market-share-with-london-move-47139282',
    },
    {
      img: image2,
      date: "AUGUST 23, 2016",
      title: "Google Maps co-founder Lars Rasmussen backs Australian start-up LawAdvisor",
      description: "Google Maps co-founder and former Facebook executive Lars Rasmussen has invested in Australian legal tech start-up...",
      url: "https://www.afr.com/technology/google-maps-cofounder-lars-rasmussen-backs-australian-startup-lawadvisor-20160819-gqx3bq"
    },
    {
      img: image3,
      date: "JULY 19, 2019",
      title: "LawAdvisor invests in legal automation, acquires early-stage startup Kobi.ai from Mills Oakley",
      description: "Melbourne-founded legal management software-as-a-service startup LawAdvisor has...",
      url: "https://www.globallegalpost.com/big-stories/lawadvisor-in-start-up-acquisition-70630780/"
    },
    {
      img: image4,
      date: "SEPTEMBER 6, 2017",
      title: "Lonely Planet legal adopts LawAdvisor Corporate for managing outside counsel",
      description: "Lonely Planet has helped to develop and now adopted an outside counsel procurement and management platform from Melbourne-founded legal tech startup LawAdvisor, which is backed by Janders Dean and launched in the UK this year.",
      url: "https://legaltechnology.com/lonely-planet-legal-adopts-lawadvisor-corporate-for-managing-outside-counsel/"
    },
    {
      img: image5,
      date: "JULY 17, 2015",
      title: "Want legal advice? Don't ask Siri: upstart web service opens law access",
      description: "Lawyers are scary. A new tech start-up wants to change that.",
      url: "https://www.afr.com/companies/professional-services/lawyers-as-tecopa-pupfish-can-they-avoid-extinction-20150710-gi9iix"
    }
  ];


  return (
    <div className={`fit-width ${className || ""}`}>
      <News news={news} />
    </div>
  )
};

export default NewsList
